.App {
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App-logo {
  width: 80vw;
}

.Links {
  font-size: 5vw;
  color: white;
  background-color: black;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Fancy {
  font-family: 'Poiret One', sans-serif;
  color: #f7c568; 
  pad: 30
}

.About {
  font-family: 'Poiret One', sans-serif;
  color: #f7c568;
  font-size: 4vw;
  width: 80vw;
  padding-left: 10px;
}

.Harvest {
  font-family: 'Poiret One', sans-serif;
  color: #f7c568;
  font-size: 4vw;
  width: 80vw;
  padding-top: 20px;
  padding-left: 10px;
  font-weight: bold;
  text-align: center;
}


@media screen and (min-width: 1200px) {
  .App-logo {
    width: 1000px;
  }

  .About {
    width: 50vw;
  }
}

@media screen and (min-width: 980px) {
  .Links {
     font-size: 40px;
     padding-top: 50px;
  }

  .About {
    font-size: 30px;
    padding-left: 30px;
  }

  .Harvest {
    font-size: 30px;
    width: 50vw;
    padding-top: 50px;
  }
}

@media screen and (min-width: 420px) {
  .Links {
     padding-top: 50px;
  }

  .Harvest {
    padding-top: 50px;
  }
}